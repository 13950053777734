const defaultToken = 'no-csrf-token-found'

const authenticityToken = (): string => {
  const metaTag = document.querySelector('meta[name="csrf-token"]')

  if (metaTag) {
    return metaTag.getAttribute('content') || defaultToken
  } else {
    return defaultToken
  }
}

export const fetchJSON = async <S, F = HAT.IErrorResponse>(
  url: string,
  init?: RequestInit,
) => {
  init = init || {}

  return parseJSONResponse<S, F>(
    await localFetch(
      url,
      mergeInit(init, {
        headers: { Accept: 'application/json' },
      }),
    ),
  )
}

export const localFetch = (url: string, init?: RequestInit) => {
  init = init || {}

  return fetch(
    url,
    mergeInit(init, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticityToken(),
      },
      credentials: 'same-origin',
    }),
  )
}

const mergeInit = (
  overrides: RequestInit,
  defaults: RequestInit,
): RequestInit => ({
  ...defaults,
  ...overrides,
  headers: {
    ...defaults.headers,
    ...overrides.headers,
  },
})

interface IParseJSONWithSuccess<S> {
  success: S
  error: null
}

interface IParseJSONWithFailure<F> {
  success: null
  error: F
}

type IParseJSONResponseReturn<S, F> =
  | IParseJSONWithSuccess<S>
  | IParseJSONWithFailure<F>

export const parseJSONResponse = async <S, F = HAT.IErrorResponse>(
  response: Response,
): Promise<IParseJSONResponseReturn<S, F>> => {
  if (response.ok) {
    return { success: (await response.json()) as S, error: null }
  } else {
    return { error: (await response.json()) as F, success: null }
  }
}
