import * as React from 'react'

interface IRadioOption<T> {
  name: T
  label: string
}

interface IProps<T> {
  options: IRadioOption<T>[]
  selected: T
  onChange: (name: T) => any
  prefix: string
}

export class RadioButtonGroup<T extends string = string> extends React.Component<IProps<T>, {}> {
  public handleChange(optionName: T) {
    const { onChange } = this.props

    onChange(optionName)
  }

  public renderOption(option: IRadioOption<T>) {
    const { selected, prefix } = this.props
    const isChecked = option.name == selected
    const uniqueName = option.name + prefix

    return (
      <label
        className={`btn btn-outline-primary ${isChecked ? 'active' : ''}`}
        htmlFor={uniqueName}
        key={uniqueName}
      >
        <input
          type="radio"
          onChange={this.handleChange.bind(this, option.name)}
          name={option.name}
          id={uniqueName}
          checked={isChecked}
        />
        {option.label}
      </label>
    )
  }

  public render() {
    const { options } = this.props
    const renderedOptions = options.map((option) => this.renderOption(option))

    return (
      <div className="btn-group btn-group-toggle">
        {renderedOptions}
      </div>
    )
  }
}
