import * as React from 'react'
import * as ReactDOM from 'react-dom'

interface IComponentsObject {
  [key: string]: React.ComponentType<any>
}

export default function(components: IComponentsObject) {
  Array.from(document.querySelectorAll('[data-react-class]')).forEach((item) => {
    const name = item.getAttribute('data-react-class') || 'blank-react-class'
    const props = JSON.parse(item.getAttribute('data-react-props') || '{}')
    const Component = components[name]

    if (Component) {
      ReactDOM.render(
        <Component {...props} />,
        item,
      )
    }
  })
}
