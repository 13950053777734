import { isoDateString } from './date'
export const creditCardPath = '/portal/credit_card'
export const customerPath = '/portal/customer'
export const newCreditPurchasePath = '/portal/credit_purchases/new'
export const creditPurchasePath = '/portal/credit_purchases'

export const historyReportCreatePath = '/api/v1/hail_history_reports'
export const historyReportShowPath =
  (id: number, withLocations: boolean) =>
    `/api/v1/hail_history_reports/${id}?include_locations=${withLocations ? '1' : '0'}`
export const historyReportPurchasePath =
  (id: number) => `/api/v1/hail_history_reports/${id}/purchase`

export const dashboardAPIPath =
  (
    { stormDays, historyReportDays, stormReportsPage }:
      { stormDays: number, historyReportDays: number, stormReportsPage: number },
  ) =>
    `/admin?storm_days=${stormDays}&history_report_days=${historyReportDays}&storm_reports_page=${stormReportsPage}`

export const stormSwathPath = (stormDate: Date) => `/admin/storms/${isoDateString(stormDate)}/swath.json`
