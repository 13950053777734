interface IPoint {
  latitude: number
  longitude: number
}

export const getBounds = (points: IPoint[]): [IPoint, IPoint] => {
  const min = { latitude: 999, longitude: 999 }
  const max = { latitude: -999, longitude: -999 }
  points.forEach((point) => {
    if (point.latitude > max.latitude) {
      max.latitude = point.latitude
    }
    if (point.latitude < min.latitude) {
      min.latitude = point.latitude
    }
    if (point.longitude > max.longitude) {
      max.longitude = point.longitude
    }
    if (point.longitude < min.longitude) {
      min.longitude = point.longitude
    }
  })

  return [min, max]
}

